export const queue = (() => {
  let isProcessing = false;
  const list: Array<() => Promise<void>> = [];

  const processQueue = () => {
    if (isProcessing || list.length === 0) return;

    isProcessing = true;
    const task = list.shift();
    if (task) {
      task()
        .catch((error) => {
          console.error('Error in task:', error);
        })
        .finally(() => {
          isProcessing = false;
          processQueue();
        });
    }
  };

  return (callback: () => Promise<void>) => {
    list.push(callback);
    processQueue();
  };
})();
